import { useState ,useEffect} from 'react';
import CourseCard from '../CourseCard/CourseCard';
import Placeholder from '../Placeholder/Placeholder';
import SchedulePlaceholder from '../../Images/SchedulePlaceholder.svg';
import TrainingForm from '../TrainingForm/TrainingForm';
import { Helmet } from 'react-helmet-async';
import './SchedulePage.css';
const SchedulePage = () => {
const [courses,setCourses] = useState([]);
const [courseName,setCourseName] = useState();
const [courseDate,setCourseDate] = useState();
const [error, setError] = useState(null);
const [showTrainingForm,setShowTrainingForm] = useState(false);
useEffect(() => { 
  fetch(process.env.REACT_APP_Courses_API || '../../Apis/Courses.php')
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then((fetchedData) => {
      setCourses(fetchedData);
      setError(null); 
    })
    .catch((error) => {
      setError(error.message);
    });
}, []);
let coursesCards;
const TrainingFormSetter = (courseName,courseDate)=>{
  setShowTrainingForm(true);
  setCourseName(courseName);
  setCourseDate(courseDate);
  console.log(courseName,courseDate,showTrainingForm);
}
if (courses && courses.length > 0 && !error) {
  coursesCards = courses.map((course) => (
    <CourseCard key={course.Id} course={course} TrainingFormSetter={TrainingFormSetter}/>
  ));
} else {
  coursesCards = (
    <Placeholder img={SchedulePlaceholder} text="We Will Add Our Schedule Soon. Stay tuned!" />
  );
}
  return (
   <div className="SchedulePage">
    <Helmet>
    <meta name="description" content="Stay updated with our upcoming training sessions and events! Explore our schedule and register now." />
<meta name="keywords" content="training sessions, upcoming events, event schedule, register for training, IT training schedule, professional development events, training calendar, event registration, educational events, training programs" />
    </Helmet>
    

      <h1 className="Title">{showTrainingForm? "Training Registration " :"Schedule"}</h1>
      {!showTrainingForm &&  <section className="CourseList">
        <div className="CalendarContainer CourseList">
           {coursesCards}
        </div>
    </section>}

    
    {showTrainingForm && <TrainingForm courseName={courseName} courseDate={courseDate}/>}
    </div>
  )
}


export default SchedulePage
