import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import HomePage from './Components/HomePage/HomePage';
import AboutUsPage from './Components/AboutUsPage/AboutUsPage';
import OurServicesPage from './Components/OurServicesPage/OurServicesPage';
import OurPartnersPage from './Components/OurPartnersPage/OurPartnersPage';
import SchedulePage from './Components/SchedulePage/SchedulePage';
import JobsPage from './Components/JobsPage/JobsPage';
import BlogPage from './Components/BlogPage/BlogPage';
import Placeholder from './Components/Placeholder/Placeholder';
import NotFoundImg from './Images/404SVG.svg';
import TrainingForm from './Components/TrainingForm/TrainingForm';
import JobDetails from './Components/JobDetails/JobDetails';
import { HelmetProvider } from 'react-helmet-async';
import {Route,Routes } from 'react-router-dom';
import ReactGA from 'react-ga4';
import './App.css';

const MEASURING_ID = process.env.REACT_APP_Google_Analytics || "G-T40Z98FS64";
ReactGA.initialize(MEASURING_ID);
function App() {
return (
  <HelmetProvider>
    <div className="App">
       <Header/>
        <Routes>
       <Route path='/' element={<HomePage/>}/>
       <Route path='/AboutUs' element={<AboutUsPage/>}/>
       <Route path='/OurServices' element={<OurServicesPage/>}/>
       <Route path='/OurPartners' element={<OurPartnersPage/>}/>
       <Route path='/Schedule' element={<SchedulePage/>}/>
       <Route path='/Jobs' element={<JobsPage/>}/>
       <Route path='/Training/:Course/:Day/:Month' element={<TrainingForm/>}/>
       <Route path='/JobDetails' element={<JobDetails/>}/>
       {/* <Route path='/Blog' element={<BlogPage/>}/> */}
       <Route path='/*' element={<Placeholder img={NotFoundImg} text="Page Not Found" buttonRoute="/" buttonText="Back To Home"/>}/>
        </Routes>
      <Footer className="FooterComponent"/>
    </div>
    </HelmetProvider>
  );
}

export default App;
